import { useEffect } from 'react'
import {
    GetLatestUserProgressQuery,
    useGetLatestUserProgressLazyQuery,
    useGetLatestUserProgressQuery,
    UserJourneyProgressV2
} from '../../graphql/generated/autogenerated'

const dataSelector = (
    data: GetLatestUserProgressQuery
): UserJourneyProgressV2 => {
    return (
        (data?.journeys?.getLatestUserProgressV2 as UserJourneyProgressV2) ??
        null
    )
}

export type UseGetLatestUserJourneyProgressResult = {
    latestProgress: UserJourneyProgressV2
    isLoading: boolean
    latestProgressNotFound: boolean
    refetch: () => void
}

// Temp function to allow the lazy calling and prevent being called in the Intention Flow
export function useGetLatestUserJourneyProgressLazy(fetchProgress = false) {
    const [getLatestUserProgress, { data, refetch, loading }] =
        useGetLatestUserProgressLazyQuery({
            errorPolicy: 'all',
            notifyOnNetworkStatusChange: true
        })

    useEffect(() => {
        if (fetchProgress) {
            getLatestUserProgress()
        }
    }, [fetchProgress, getLatestUserProgress])

    const latestUserJourneyProgress = dataSelector(data)

    return {
        latestProgress: latestUserJourneyProgress,
        isLoading: loading,
        latestProgressNotFound: !loading && !latestUserJourneyProgress,

        refetch
    }
}

export function useGetLatestUserJourneyProgress(): UseGetLatestUserJourneyProgressResult {
    const { data, refetch, loading } = useGetLatestUserProgressQuery({
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
    })

    const latestUserJourneyProgress = dataSelector(data)

    return {
        latestProgress: latestUserJourneyProgress,
        isLoading: loading,
        latestProgressNotFound: !loading && !latestUserJourneyProgress,

        refetch
    }
}
