import { Apollo } from '@thriveglobal/thrive-web-core'
import {
    type GetContentByTopicQuery,
    type GetContentByTopicQueryVariables,
    type LearnContentItem,
    useGetContentByTopicLazyQuery
} from '../../graphql/generated/autogenerated'
import { useCallback, useMemo } from 'react'

export type UseJourneyRelatedContentResult = {
    getMoreLikeThisContent: (
        id: string,
        pageSize?: number
    ) => Promise<
        Apollo.QueryResult<
            GetContentByTopicQuery,
            GetContentByTopicQueryVariables
        >
    >
    moreLikeThisContent: LearnContentItem[]
    isLoading: boolean
    error: Apollo.ApolloError
    called: boolean
}

export function moreLikeThisContentSelector(
    query: GetContentByTopicQuery
): LearnContentItem[] {
    return (query.content?.thrive?.getContentByTopic?.itemPage.items ||
        []) as LearnContentItem[]
}

export function useJourneyRelatedContent(): UseJourneyRelatedContentResult {
    const [
        get,
        {
            loading,
            data = { content: { thrive: { getContentByTopic: null } } },
            error,
            called
        }
    ] = useGetContentByTopicLazyQuery({
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
    })

    const getMoreLikeThisContent = useCallback(
        (id: string) => {
            return get({
                variables: {
                    pageSize: 2,
                    topic: {
                        journeyUUID: id
                    }
                }
            })
        },
        [get]
    )

    const moreLikeThisContent = useMemo(() => {
        return moreLikeThisContentSelector(data)
    }, [data])

    return {
        getMoreLikeThisContent,
        moreLikeThisContent,
        isLoading: loading,
        error,
        called
    }
}
