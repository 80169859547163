import {
    GetUncompletedJourneyProgressQuery,
    UncompletedJourneyProgress,
    useGetUncompletedJourneyProgressQuery
} from '../../graphql/generated/autogenerated'

export type UseGetUncompletedJourneysProgressResult = {
    isLoading: boolean
    uncompletedJourneysProgress: UncompletedJourneyProgress[]
    progressNotFound: boolean
    refetch: () => void
}

const selectUncompletedJourneysProgress = (
    data: GetUncompletedJourneyProgressQuery | undefined
): UncompletedJourneyProgress[] => {
    return (
        (data?.journeys
            ?.getUncompletedJourneysProgress as UncompletedJourneyProgress[]) ??
        []
    )
}

export function useGetUncompletedJourneysProgress(): UseGetUncompletedJourneysProgressResult {
    const { data, loading, refetch } = useGetUncompletedJourneyProgressQuery({
        errorPolicy: 'all'
    })

    const uncompletedJourneysProgress = selectUncompletedJourneysProgress(data)

    return {
        isLoading: loading,
        uncompletedJourneysProgress,
        progressNotFound: !loading && !uncompletedJourneysProgress,
        refetch
    }
}
