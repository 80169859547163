import {
    GetCoreJourneysQuery,
    Journey,
    useGetCoreJourneysQuery
} from '../../graphql/generated/autogenerated'

interface UseGetCoreJourneysResult {
    journeys: Journey[]
    isLoading: boolean
    journeysNotFound: boolean
}

const dataSelector = (data: GetCoreJourneysQuery | undefined) => {
    return (data?.journeys?.getCoreJourneys as Journey[]) ?? []
}

export const useGetCoreJourneys = (
    adaptToIntentions = false
): UseGetCoreJourneysResult => {
    const { loading, data } = useGetCoreJourneysQuery({
        variables: {
            adaptToIntentions: adaptToIntentions
        },
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
    })

    const journeys = dataSelector(data)

    const journeysNotFound = !loading && journeys.length === 0

    return {
        journeys: journeys,
        isLoading: loading,
        journeysNotFound
    }
}
