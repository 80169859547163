import { ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import {
    ThriveFullscreenLoading,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { ROUTES } from './routes'
import { useSyncAppleHealthData } from '@thriveglobal/thrive-web-wearables-core'
import { FeatureFlags } from '../constants'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import ExperienceRouter from '../pages/ExperienceRouter'

const DailyIntelligentExperiencePage = lazy(
    () =>
        import('../pages/dailyExperience/pages/DailyIntelligentExperiencePage')
)

const MicrostepsPage = lazy(
    () => import('../pages/microsteps/pages/Microsteps')
)

const SetIntentionPage = lazy(
    () => import('../pages/intention/SetIntentionPage')
)

const EditIntentionPage = lazy(
    () => import('../pages/intention/EditIntentionPage')
)

const Router: React.FC = () => {
    const theme = useTheme()

    useSyncAppleHealthData()

    const isNewDailyExperienceEnabled = useIsFeatureEnabled(
        FeatureFlags.isNewDailyExperienceEnabled,
        false,
        true
    )

    const hasJourneysMicrosteps = useIsFeatureEnabled(
        FeatureFlags.journeysMicrosteps,
        false,
        true
    )

    const hasIntentionSetting = useIsFeatureEnabled(
        FeatureFlags.HasIntentionSetting,
        false,
        true
    )

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Suspense fallback={<ThriveFullscreenLoading />}>
                    <Switch>
                        {hasIntentionSetting && (
                            <ProtectedRoute
                                path={ROUTES.SET_INTENTION}
                                component={SetIntentionPage}
                            />
                        )}
                        {hasIntentionSetting && (
                            <ProtectedRoute
                                path={ROUTES.EDIT_INTENTION}
                                component={EditIntentionPage}
                            />
                        )}
                        {isNewDailyExperienceEnabled && (
                            <ProtectedRoute
                                path={ROUTES.DAILY_INTELLIGENT_EXPERIENCE}
                                component={DailyIntelligentExperiencePage}
                            />
                        )}
                        {hasJourneysMicrosteps && (
                            <ProtectedRoute
                                path={ROUTES.JOURNEY_MICROSTEPS}
                                component={MicrostepsPage}
                            />
                        )}
                        <ProtectedRoute
                            path={ROUTES.JOURNEYS}
                            component={ExperienceRouter}
                        />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
