import {
    type GetActiveMicrostepsV2Query,
    useGetActiveMicrostepsV2LazyQuery
} from '../../graphql/generated/autogenerated'
import { useCallback, useMemo, useState } from 'react'
import { Apollo, format } from '@thriveglobal/thrive-web-core'
import { MICROSTEP_CHECKIN_DATE_FORMAT } from '../../constants'

export type LoadMicrostepsOptions = {
    // date in the format of YYYY-MM-DD
    from: string
    // date in the format of YYYY-MM-DD
    to: string
}

export type LoadCheckedInMicrosteps = (options: LoadMicrostepsOptions) => void

export type UseGetCheckedInMicrostepsByDate = [
    LoadCheckedInMicrosteps,
    {
        checkedInMicrostepIds: string[]
        isLoading: boolean
        error: Apollo.ApolloError
    }
]

export function useGetCheckedInMicrostepsByDate(): UseGetCheckedInMicrostepsByDate {
    const [dailyMicrostepsData, setDailyMicrostepsData] =
        useState<GetActiveMicrostepsV2Query>(null)

    const [getActiveMicrosteps, { loading, error }] =
        useGetActiveMicrostepsV2LazyQuery()

    const loadCheckedInMicrosteps = useCallback(
        async (options: LoadMicrostepsOptions) => {
            const today = format(new Date(), MICROSTEP_CHECKIN_DATE_FORMAT)

            const { from = today, to = today } = options

            const { data } = await getActiveMicrosteps({
                variables: {
                    from,
                    to
                },
                fetchPolicy: 'network-only'
            })

            setDailyMicrostepsData(data)
        },
        [getActiveMicrosteps]
    )

    const checkedInMicrostepIds = useMemo(() => {
        const checkedMicrostepsByDate =
            dailyMicrostepsData?.today?.getAllMicrostepCheckinProgressV2 ?? []

        const allCheckedMicrostepIds = checkedMicrostepsByDate.reduce<string[]>(
            (acc, microstepsProgress) => {
                acc.push(...(microstepsProgress?.microstepsCheckedIn ?? []))

                return acc
            },
            []
        )

        return allCheckedMicrostepIds
    }, [dailyMicrostepsData?.today?.getAllMicrostepCheckinProgressV2])

    return [
        loadCheckedInMicrosteps,
        {
            checkedInMicrostepIds: checkedInMicrostepIds,
            isLoading: loading,
            error
        }
    ]
}
