import {
    Journey,
    UserJourneyProgressV2
} from '../../graphql/generated/autogenerated'
import { useEffect, useMemo, useState } from 'react'
import { getJourneyIcon, getJourneyPalette } from '../../utils/journeys'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { SimplePaletteColorOptions } from '@mui/material'
import { useParams } from 'react-router-dom'
import { IconName } from '@fortawesome/fontawesome-common-types'

export type UseSelectedCoreJourneyResult = {
    selectedVisibleJourney: Journey | null
    isJourneySelected: boolean
    isSelectedJourneyCompleted: boolean
    selectedJourney: Journey | null
    selectedJourneyColor: SimplePaletteColorOptions
    selectedJourneyIcon: IconName
}

export function useSelectedCoreJourney(
    journeys: Journey[],
    userJourneyProgress: UserJourneyProgressV2
): UseSelectedCoreJourneyResult {
    const theme = useTheme()

    const { id: journeyIdPageParam } = useParams<{ id: string }>()

    const journeysNotFound = !journeys || journeys?.length === 0

    const currentSelectedJourney = useMemo(() => {
        if (journeysNotFound) {
            return null
        }

        const selectedJourneyId =
            userJourneyProgress?.currentJourney?.journey?.id

        return journeys.find((journey) => journey.id === selectedJourneyId)
    }, [
        journeys,
        journeysNotFound,
        userJourneyProgress?.currentJourney?.journey?.id
    ])

    const [selectedVisibleJourney, setSelectedVisibleJourney] = useState(
        currentSelectedJourney || journeys[0]
    )

    const isSelectedJourneyCompleted = useMemo(() => {
        if (userJourneyProgress && selectedVisibleJourney) {
            const isSelectedVisibleJourneyCompleted =
                (selectedVisibleJourney?.id ===
                    userJourneyProgress?.currentJourney?.journey?.id &&
                    userJourneyProgress?.currentJourney?.isCompleted) ||
                (selectedVisibleJourney?.id ===
                    userJourneyProgress?.previousJourney?.journey?.id &&
                    userJourneyProgress?.previousJourney?.isCompleted) ||
                false

            return isSelectedVisibleJourneyCompleted
        }

        return false
    }, [userJourneyProgress, selectedVisibleJourney])

    // NOTE: Need to store it in state to update value along with selectedVisibleJourney
    const [isJourneySelected, setIsJourneySelected] = useState(false)

    useEffect(
        function changeJourney() {
            const isCurrentJourneySelected = Boolean(currentSelectedJourney)
            setIsJourneySelected(isCurrentJourneySelected)

            let selectedJourney = null

            if (!currentSelectedJourney) {
                const journeyByPageParam = journeys.find(
                    (journey) => journey.id === journeyIdPageParam
                )

                if (journeyByPageParam) {
                    selectedJourney = journeyByPageParam
                }

                if (!journeyByPageParam && !journeysNotFound) {
                    selectedJourney = journeys[0]
                }
            } else {
                selectedJourney = currentSelectedJourney
            }

            setSelectedVisibleJourney(selectedJourney)
        },
        [currentSelectedJourney, journeyIdPageParam, journeys, journeysNotFound]
    )

    const selectedJourneyColor = useMemo(() => {
        return getJourneyPalette(
            theme.palette,
            selectedVisibleJourney?.coreType
        )
    }, [selectedVisibleJourney?.coreType, theme.palette])

    const selectedJourneyIcon = useMemo(() => {
        return getJourneyIcon(selectedVisibleJourney?.coreType)
    }, [selectedVisibleJourney?.coreType])

    return {
        selectedVisibleJourney: selectedVisibleJourney,
        selectedJourney: currentSelectedJourney,
        isSelectedJourneyCompleted: isSelectedJourneyCompleted,
        isJourneySelected: isJourneySelected,
        selectedJourneyColor,
        selectedJourneyIcon
    }
}
