import { useStartJourneyPage } from '../journeys/startJourneyPage/useStartJourneyPage'
import { ThriveFullscreenLoading } from '@thriveglobal/thrive-web-leafkit'
import { ROUTES } from '../../routes/routes'
import { type ComponentType, useEffect } from 'react'
import {
    useCrossAppNavigation,
    useIsFeatureEnabled
} from '@thriveglobal/thrive-web-core'
import { FeatureFlags } from '../../constants'
import { useOnboardingStatus, UserOnboardingStatus } from '../../hooks'

// NOTE: This HOC is going to be used later in the following PR for redirecting users to the onboarding flow
export function withOnboardingRedirect<Props>(
    IntentionPage: ComponentType<Props>
) {
    return function IntentionPageWithOnboardingRedirect(props: Props) {
        const newOnboardingFlowEnabled = useIsFeatureEnabled(
            FeatureFlags.OnboardingFlow,
            false,
            true
        )

        const newDailyExperienceEnabled = useIsFeatureEnabled(
            FeatureFlags.isNewDailyExperienceEnabled,
            false,
            true
        )

        const navigate = useCrossAppNavigation()

        const { selectedJourney, isLoading: isLoadingJourneys } =
            useStartJourneyPage()

        const {
            status,
            loading: isOnboardingStatusLoading,
            setOnboardingCompleted
        } = useOnboardingStatus()

        useEffect(() => {
            if (selectedJourney) {
                setOnboardingCompleted()
            }
        }, [selectedJourney, setOnboardingCompleted])

        const shouldNavigateToOnBoarding =
            newOnboardingFlowEnabled &&
            (!selectedJourney || status == UserOnboardingStatus.NotStarted)

        if (isLoadingJourneys || isOnboardingStatusLoading) {
            return <ThriveFullscreenLoading />
        }

        if (
            selectedJourney ||
            status == UserOnboardingStatus.Completed ||
            status == UserOnboardingStatus.Skipped
        ) {
            if (newDailyExperienceEnabled) {
                navigate(ROUTES.DAILY_INTELLIGENT_EXPERIENCE)
                return null // Render nothing while redirect is happening
            } else {
                return <IntentionPage {...props} />
            }
        }

        if (shouldNavigateToOnBoarding) {
            navigate(ROUTES.ONBOARDING)
            return null
        }
    }
}
