import { lazy, memo } from 'react'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { FeatureFlags } from '../constants'
import { Redirect } from 'react-router-dom'
import { ROUTES } from '../routes/routes'
import { withOnboardingRedirect } from './intention/withOnboardingRedirect'

const StartJourneyPage = lazy(
    () => import('./journeys/startJourneyPage/StartJourneyPage')
)
const IntentionPage = lazy(() => import('./intention/IntentionPage'))
const IntentionPageWithOnboardingRedirect =
    withOnboardingRedirect(IntentionPage)

const ExperienceRouter = () => {
    const newDailyExperienceEnabled = useIsFeatureEnabled(
        FeatureFlags.isNewDailyExperienceEnabled,
        null,
        true
    )

    const hasIntentionSettingEnabled = useIsFeatureEnabled(
        FeatureFlags.HasIntentionSetting,
        null,
        true
    )

    // We want to prevent the StartJourneyPage from rendering until we know the feature flags are fully loaded
    if (
        newDailyExperienceEnabled === null ||
        hasIntentionSettingEnabled === null
    ) {
        return null
    }

    if (newDailyExperienceEnabled) {
        return <Redirect to={ROUTES.DAILY_INTELLIGENT_EXPERIENCE} />
    }

    // Send user to new flow if intention setting is enabled
    if (hasIntentionSettingEnabled) {
        return <IntentionPageWithOnboardingRedirect />
    }

    //Otherwise they get the existing experience
    return <StartJourneyPage />
}

export default memo(ExperienceRouter)
