import { applicationTheme } from '@thriveglobal/thrive-web-leafkit'
import { type SimplePaletteColorOptions } from '@mui/material'

type JourneyPaletteType = keyof typeof applicationTheme.palette.journeys
export type JourneyPaletteItem = SimplePaletteColorOptions

export const getJourneyPalette = (
    themePalette: typeof applicationTheme.palette,
    journeyName: string
): JourneyPaletteItem => {
    const journey = journeyName?.toLowerCase()
    const palette = themePalette.journeys[journey as JourneyPaletteType]

    if (journey === 'stress_management') {
        return themePalette.journeys.stressManagement
    }

    if (palette) {
        return palette
    }

    return themePalette.journeys.focus
}
