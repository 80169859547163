import { useCallback } from 'react'
import { Apollo } from '@thriveglobal/thrive-web-core'
import {
    type MarkJourneyDailyStepAsCompletedMutation,
    useMarkJourneyDailyStepAsCompletedMutation
} from '../../graphql/generated/autogenerated'

export type UseCompleteDailyStepResult = {
    completeDailyStep: (
        dailyStepId: string
    ) => Promise<Apollo.FetchResult<MarkJourneyDailyStepAsCompletedMutation>>
    isLoading: boolean
    error: Apollo.ApolloError
}

export const useCompleteDailyStep = (): UseCompleteDailyStepResult => {
    const [markDailyCheckAsCompleted, { data, loading, error }] =
        useMarkJourneyDailyStepAsCompletedMutation({
            errorPolicy: 'all'
        })

    const completeDailyStep = useCallback(
        (dailyStepId: string) => {
            return markDailyCheckAsCompleted({
                variables: {
                    dailyStepId
                }
            })
        },
        [markDailyCheckAsCompleted]
    )

    return {
        completeDailyStep,
        isLoading: loading,
        error
    }
}
