export enum FeatureFlags {
    OnboardingFlow = 'newOnboardingFlow',
    IsTodayResetOutOfFeed = 'isTodaysResetOutOfOverviewFeed',
    HideDailyCheckIn = 'journeysHideDailyCheckIn',
    HasAcuteCareAccess = 'hasAcuteCareAccess',
    isNewDailyExperienceEnabled = 'isNewDailyExperienceEnabled',
    journeysMicrosteps = 'journeysMicrosteps',
    HasIntentionSetting = 'HasIntentionSetting',
    hasResetPostWatchScreen = 'isResetFeedbackEnabled',
    IsNewCompanyResourceEnabled = 'isNewCompanyResourceCardEnabled'
}
