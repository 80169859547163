import {
    GetDailyStepByIdQuery,
    JourneyDailyStep,
    useGetDailyStepByIdQuery
} from '../../graphql/generated/autogenerated'

export type UseGetDailyStepByIdParams = {
    dailyStepId?: string | null
    skip?: boolean
}

export type UseGetDailyStepByIdResult = {
    data: JourneyDailyStep | null
    isLoading: boolean
    error: Error | undefined
    notFound: boolean
}

function selectDailyStep(data: GetDailyStepByIdQuery) {
    return (data?.journeys?.getJourneyDailyStep as JourneyDailyStep) ?? null
}

export function useGetDailyStepById({
    skip = false,
    dailyStepId = null
}: UseGetDailyStepByIdParams = {}): UseGetDailyStepByIdResult {
    const { data, loading, error } = useGetDailyStepByIdQuery({
        variables: {
            contentId: dailyStepId
        },
        skip: skip,
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
    })

    const dailyStep = selectDailyStep(data)

    return {
        data: dailyStep,
        isLoading: loading,
        error: error,
        notFound: !loading && !dailyStep
    }
}
