import { useSelectedCoreJourney } from '../../../context/journey/useSelectedCoreJourney'
import {
    useGetLatestUserJourneyProgress,
    useGetCoreJourneys
} from '../../../data/journeys'
import { Journey } from '../../../graphql/generated/autogenerated'
import { useCallback } from 'react'

export type UseStartJourneyPageResult = {
    journeys: Journey[]
    selectedJourney: Journey | null

    dataNotFound: boolean
    journeysNotFound: boolean
    isLoading: boolean
    refetch: () => void
}

export function useStartJourneyPage(): UseStartJourneyPageResult {
    const {
        journeys,
        isLoading: isCoreJourneysLoading,
        journeysNotFound
    } = useGetCoreJourneys()

    const {
        latestProgress,
        isLoading: isLatestProgressLoading,
        refetch: refetchLatestProgress,
        latestProgressNotFound
    } = useGetLatestUserJourneyProgress()

    const { selectedJourney } = useSelectedCoreJourney(journeys, latestProgress)

    const refetch = useCallback(() => {
        refetchLatestProgress()
    }, [refetchLatestProgress])

    const dataNotFound = journeysNotFound || latestProgressNotFound

    const isLoading = isCoreJourneysLoading || isLatestProgressLoading

    return {
        journeys,
        selectedJourney,

        dataNotFound,
        journeysNotFound,
        isLoading,
        refetch
    }
}
