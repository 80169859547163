import { useCallback } from 'react'
import { Apollo } from '@thriveglobal/thrive-web-core'
import {
    SelectCoreJourneyMutation,
    useSelectCoreJourneyMutation
} from '../../graphql/generated/autogenerated'

export type UseSelectCoreJourneyResult = {
    selectCoreJourney: (
        journeyId: string
    ) => Promise<Apollo.FetchResult<SelectCoreJourneyMutation>>
    isLoading: boolean
    error: Apollo.ApolloError
}

export const useSelectCoreJourney = (): UseSelectCoreJourneyResult => {
    const [selectCoreJourney, { data, loading, error }] =
        useSelectCoreJourneyMutation({
            errorPolicy: 'all'
        })

    const selectNewCoreJourney = useCallback(
        (journeyId: string) => {
            return selectCoreJourney({
                variables: {
                    journeyId
                },
                refetchQueries: ['getLatestUserProgress']
            })
        },
        [selectCoreJourney]
    )

    return {
        selectCoreJourney: selectNewCoreJourney,
        isLoading: loading,
        error
    }
}
